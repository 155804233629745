
import {computed} from "vue";
import {useStore} from "vuex";

export default {
  name: "NavBar",

  methods: {
    getHomeText(): string {
      let toReturn = 'Home';
      let name = localStorage.getItem("user-name");
      if (name != null) {
        toReturn = toReturn + ' for the ' + name.toUpperCase()
      }
      return toReturn;
    }
  },

  setup() {
    const store = useStore();
    // const homeText = ref("Home");

    const auth = computed(() => store.state.authenticated);

    const logout = async () => {
      localStorage.removeItem('jwt')
      localStorage.removeItem('user-name')
      window.location.reload();
    }
    // console.log(auth.value);
    // console.log(localStorage.getItem("user-name"))
    // if (auth.value && localStorage.getItem("user-name") != null) {
    //   homeText.value = 'Home for ' + localStorage.getItem("user-name");
    // }

    return {
      auth,
      logout,
      // homeText,
    }
  }
}
