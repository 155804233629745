
import {reactive} from 'vue';
import {useRouter} from "vue-router";

export default {
  name: "RegisterView",
  setup() {
    const data = reactive({
      name: '',
      email: '',
      password: ''
    });
    const router = useRouter();

    const submit = async () => {
      console.log('>>> R E G I S T E R <<<');
      console.log(data);
      console.log(process.env.VUE_APP_ROOT_API);
      await fetch(process.env.VUE_APP_ROOT_API + '/users/register', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(data)
      });

      await router.push('/login');
    }

    return {
      data,
      submit
    }
  }
}
