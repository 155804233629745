
import {onMounted, ref, computed} from "vue";
// import type { Ref } from 'vue';
import {useStore} from "vuex";
import { getFormattedDateTime } from "../services/todo-view-service";
import { Todo } from "../types/todo.Types.interface";

export default {
  name: "HomeView",

  methods: {
    getFormattedDateTime(input: string): string {
      return getFormattedDateTime(input);
    },
  },

  setup() {
    const message = ref("You are not logged in!");
    const store = useStore();
    const todos = ref<Array<Todo>>([]);

    const auth = computed(() => store.state.authenticated);
    onMounted(async () => {
      try {
        const value = localStorage.getItem("jwt") || "";
        let name;

        let response = await fetch(process.env.VUE_APP_ROOT_API + '/users/user', {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': value
          },
          credentials: 'include'
        });

        let content = await response.json();

        if (content.name) {
          name = content.name;
          message.value = `Hi ${name}`;
          await store.dispatch('setAuth', true);
        }
        response = await fetch(process.env.VUE_APP_ROOT_API + '/todos/list', {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': value
          },
          credentials: 'include'
        });
        todos.value = await response.json();

      } catch (e) {
        await store.dispatch('setAuth', false);
      }
    });

    return {
      message,
      todo: todos,
      auth,
    }
  },
}
