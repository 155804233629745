
import {reactive} from 'vue';
import {useRouter} from "vue-router";
import {useStore} from "vuex";


export default {
  name: "LoginView",
  setup() {
    const store = useStore();

    const data = reactive({
      email: '',
      password: ''
    });

    const router = useRouter();

    const submit = async () => {
      try {
        const response = await fetch(process.env.VUE_APP_ROOT_API + '/users/login', {
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          credentials: 'include',
          body: JSON.stringify(data)
        });
        const content = await response.json();
        if (content.jwt) {
          localStorage.setItem('jwt', content.jwt);
          localStorage.setItem('user-name', content.name);
          await store.dispatch('setAuth', true);
        }
      } catch (e) {
        console.log(e)
      }

      router.push('/');
    }

    return {
      data,
      submit
    }
  }
}
